import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import PromptFooter from './PromptFooter';
import { Button, Div, Page } from '../../framework';
import { setFlyoutOpen, setMenuMode } from '../../store/app.slice';
import { CheckCircleFilled } from '@ant-design/icons';

import './PromptInstall.scss';
import { RootState } from '../../store/store';

const { Title, Paragraph } = Typography;

const PromptPage = () => {
  const dispatch = useDispatch();
  const { browser, promptEvent } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  const promptInstall = async () => {
    if (!promptEvent) {
      return;
    }

    const result = await promptEvent.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
  };

  return (
    <Page className='page-prompt-install talent-page'>
      <Div className='logo-box'>
        <img
          className='logo'
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/logo_shaded.png'
          alt='Heycast.me logo'
        />
      </Div>
      <Title level={2}>Manage your Job and get updates with our Web App</Title>

      <Paragraph>
        Stay updated with our Web App directly on your device:
      </Paragraph>
      <Div className='benefit-list'>
        <Paragraph>
          <CheckCircleFilled className='check-icon' /> Receive instant
          notifications about new job opportunities
        </Paragraph>
        <Paragraph>
          <CheckCircleFilled className='check-icon' /> Receive Job Status
          update: Shortlist, confirm and Invite for casting.
        </Paragraph>
        <Paragraph>
          <CheckCircleFilled className='check-icon' /> Talent Fee Payment Status
          and Monitoring
        </Paragraph>
      </Div>

      {browser.os === 'iOS' ? (
        <PromptFooter />
      ) : (
        <Div>
          <Button onClick={promptInstall} block>
            Install Web App
          </Button>
        </Div>
      )}
    </Page>
  );
};

export default PromptPage;
