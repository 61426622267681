import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { Button, Div, Page } from '../../framework';
import { setFlyoutOpen, setMenuMode } from '../../store/app.slice';
import { registerSubscribeNotification } from '../../serviceWorkerRegistration';

import './PromptNotification.scss';
import { serviceDomain, vapidPublicKey } from '../../../config/app.config';

import { RootState } from '../../store/store';
import { Link, useHistory } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const PromptPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authUser } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  return (
    <Page className='page-prompt-notification talent-page'>
      <Div className='image-box'>
        <img
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/prompt-notification-talent.png'
          alt='Heycast.me logo'
        />
      </Div>
      <Title level={2}>Get instant update!</Title>

      <Paragraph>
        Enable web app notification so that you won’t be missing any updates.
      </Paragraph>

      <Div>
        <Button
          block
          onClick={async () => {
            console.log('vapidPublicKey', vapidPublicKey);
            await registerSubscribeNotification({
              notificationUrl: serviceDomain.notification,
              talentId: authUser.username,
              vapidPublicKey,
              successUrl: '/talent/jobs',
            });
            // history.push('/talent/jobs');
          }}
        >
          Get Update Now
        </Button>

        <Div flex style={{ justifyContent: 'center' }}>
          <Link to='/talent/jobs' className='link-button'>
            Not Now
          </Link>
        </Div>
      </Div>
    </Page>
  );
};

export default PromptPage;
