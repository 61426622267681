import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Badge, Tabs } from 'antd';
import { Page } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import { RootState } from '../../../store/store';
import AgendaTab from './AgendaTab';
import PendingTabContent from './PendingTabContent';

import './Agenda.scss';

const AgendaPage = () => {
  const { activities } = useSelector((state: RootState) => state.user);

  useResetScroll();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  return (
    <Page className='page-talent-agenda talent-page'>
      <Helmet>
        <title>Agenda - Heycast.me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>

      <Tabs
        defaultActiveKey={
          activities.pending.length > 0 ? 'pending' : 'upcoming'
        }
        centered
        items={[
          {
            key: 'pending',
            label: <Badge dot={activities.pending.length > 0}>Pending</Badge>,
            children: <PendingTabContent />,
          },
          {
            key: 'upcoming',
            label: 'Upcoming',
            children: <AgendaTab type='upcoming' />,
          },
          {
            key: 'past',
            label: 'Past',
            children: <AgendaTab type='past' />,
          },
        ]}
      ></Tabs>
    </Page>
  );
};

export default AgendaPage;
